<template>
  <div>
    <van-field v-bind='$attrs'
               :readonly='true'
               v-model='cityName'
               v-on='$listenerss'
               :required="required"
               :rules="required ? [$.rules.required] : []"
               name="所在地区"
               label="所在地区"
               placeholder="省市区县、乡镇等"
               clickable
               right-icon="arrow"
               @click='show=true' />
    <van-popup get-container="body"
               close-on-click-overlay
               :overlay="true"
               v-model="show"
               position="bottom"
               :style="{ height: '60%','border-radius':'8px 8px 0 0' }">
      <div class="flex-box flex-column full">
        <div class="flex-box middle-a title">
          <span class="f16 flex1 ml20 text-bold">请选择所在地区</span>

        </div>
        <div class="flex1 flex-box flex-column pr f13 color-title">
          <van-tabs :border='false'
                    :scrollspy='true'
                    line-height='5px'
                    v-model="selectIndex"
                    title-active-color='#262626'
                    animated
                    swipeable
                    class="full flex-box flex-column">
            <template v-for="(item,index) in selecCity">
              <van-tab :title='item.select?item.select.regionName:"请选择"'
                       :key='index'>
                <div class="full over-y pd-t10 pd-b10"
                     :key='index'
                     v-show='index == selectIndex'>
                  <div class="actives pd-t10 pd-b10 pd-l20 pd-r20"
                       style=''
                       @click='selectCityAction(item1,index)'
                       v-for="(item1,index1) in item.list"
                       :key='index1'>
                    <span v-show='item1.expand'
                          class='flex-box middle-a'
                          style="position:relative">
                      <van-icon name="success"
                                size="20"
                                class='color-primary mr10 success' />
                      {{item1.regionName}}
                    </span>
                    <span v-show='!item1.expand'>
                      {{item1.regionName}}
                    </span>
                  </div>
                </div>
              </van-tab>
            </template>

          </van-tabs>
          <!-- <div class="full-pa flex-box middle"
               v-show='APPloading'>
            <van-loading />
          </div> -->
          <div class="full-pa flex-box middle"
               v-if="initFlag">
            <div class="tc">
              <p class="fl3 color-content mb20">请求失败，刷新试试！</p>
              <van-button round
                          type="primary"
                          @click='init'
                          size="small">
                刷新
              </van-button>
            </div>
          </div>
        </div>

      </div>
    </van-popup>
  </div>
</template>

<script>
import forms from '@/mixin/Form.js';
import tab from '@/mixin/Tab.js';

export default {
  inheritAttrs: false,
  mixins: [forms, tab],
  model: {
    event: 'change',
  },
  props: {
    value: {
      default () {
        return []
      },
    },
    error: {
      default: false,
    },
    required: {
      default: false
    }
  },
  data () {
    return {
      // :'',
      show: false, //pop显示model
      cityJson: [], //整体的city数据
      selecCity: [], //渲染的主数据
      selectIndex: 0, //定位的第几个层级
      selectNext: true, //用来控制动画
      initFlag: false, //初始错误
    }
  },
  watch: {
    show: function (v) {
      if (v) { //打开选择城市按照value重新计算数组
        if (this.initFlag) {
          this.init();
        }
      }
    },
    selectIndex: function (newV, oldV) {
      this.selectNext = newV > oldV;
    }
  },
  computed: {
    animateName () { //动画效果计算
      return this.selectNext ? 'fold-left' : 'fold-right'
    },
    $listenerss () {
      let vm = this;
      return this.$.extend(true, {}, this.$listeners, {
        change () {
          // vm.$emit('change', vm.value)
        },
      })
    },
    cityName () {
      if (this.value.length) {
        return this.value.map(e => {
          try {
            return e.regionName

          } catch (error) {
            return ''

          }
        }).join(' ')
      } else {
        return '';
      }

    }
  },
  created () {
    window.cc = this;
    // this.getCitys();
    this.init()
  },
  methods: {
    init () { //地址初始化
      this.initFlag = false;
      // this.APPloading = true;
      if (this.value.length) { //如果有值需要首次加载回显
        //判断初始值的类型
        // let type = this.$.getType(this.value[0]);

        // if (type == 'number') { //如果是数字

        // }
        Promise.all([this.getCity(), ...this.value.map(e => {
          return this.getCity({
            parentId: e
          })
        })]).then(resArr => {
          console.log(resArr)
          let arr = [];
          resArr.map((e, index) => {
            let d = e.d || [];
            let select = null;
            d.forEach((e1, index1) => {
              let flag = e1.regionId == this.value[index];
              // console.log(e1.regionId ,this.value[index]);
              e1.expand = flag;
              if (flag) {
                select = e1;
              }
            });
            if (d.length) {
              arr.push({
                select,
                list: d,
              })
            }
          });
          if (arr.length) {
            this.selecCity = arr;
            // console.log(this.value.length, 111);
            this.$nextTick(() => {
              let n = this.value.length - 1;
              this.selectIndex = n;
              this.selectCityAction(arr[arr.length - 1].select, n);
            })
          }

        }).catch(() => {
          this.initFlag = true;
        }).finally(() => {
          // this.APPloading = false;
        })

      } else {
        this.getCity().then(res => { //获取一级地址
          console.log(res)
          res.d.data.forEach(e => {
            e.expand = false;
          })
          this.selecCity = [{
            select: null, //选中的数据
            list: res.d.data, //渲染的列表
          }]
          // console.log(this.selecCity);
        }).catch(() => {
          this.initFlag = true;
        })
      }
    },
    getCity (query, type) { //获取城市 type==1为需要合并请求的
      if (type == 1) {
        return this.$axios({
          url: '/common/getArea',
          data: query,
          elseData: {
            windowCache: true,
          },
          _self: this
        })
      } else {
        // this.APPloading = true;
        return this.$axios({
          url: '/common/getArea',
          data: query,
          elseData: {
            ajaxLoading: true,
            windowCache: true,
          },
          _self: this
        })
      }
    },
    selectCityAction (item, num) { //选择操作 item==>要操作数据,num为点击的是第几个层级
      // console.log(item, num);
      //先操作当前数据为选中
      this.selecCity.forEach((e, index) => {
        if (index == num) {
          e.list.forEach(e1 => {
            e1.expand = false;
          });
        }
      })
      item.expand = true; //设置当前的list为选中
      this.selecCity[this.selectIndex].select = item;
      // if (num < this.selectIndex) { //如果是前面的城市选择需要数据重新处理
      this.selecCity.splice(num + 1, 100);
      this.selectIndex = num;
      // }

      //请求是否有下一级
      this.getCity({
        parentId: item.regionId
      }).then(res => {
        console.log(res)
        if (res.d.data && res.d.data.length) { //如果有下一级
          res.d.data.forEach(e => {
            e.expand = false;
          })
          this.selecCity.push({
            select: null, //选中的数据
            list: res.d.data, //渲染的列表
          })
          setTimeout(() => {
            this.selectIndex++;
          }, 0)
        } else { //直接选中退出pop
          this.show = false;
          this.$emit('change', this.selecCity.map(e => {
            return e.select
          }));
        }
      })
    }
  },

}

</script>

<style lang="scss" scoped>
.van-icon-style {
  transform: rotate(90deg);
  right: 10px;
  top: 8px;
}

/deep/ {
  .van-icon.success {
    position: absolute;
    right: 0;
  }
  .van-cell {
    font-size: 14px;
  }
  .van-field__control {
    text-align: right;
  }
  .van-tabs__wrap {
    padding-left: 16px;
    padding-right: 16px;
  }

  .van-tabs__wrap {
    height: 43px;
  }

  .van-tab {
    flex: none;
    line-height: 43px;
  }

  .van-tabs__content {
    flex: 1;
  }
  .title {
    text-align: center;
    line-height: 48px;
  }

  .van-tabs__line {
    border-radius: 10px;
    background-image: linear-gradient(
      142deg,
      color(primary) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  .van-tab__pane {
    height: 100%;
  }
}
</style>
